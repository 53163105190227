import firebase from "../firebase";
import axios from "axios";

const rootRef = firebase.ref(
  "/1ReIEk1F8_9yTjLz0tNjFHIg9B0ZfjClMKRQEyLS0dIU/WOORDJES/"
);

class OnbekendeWoordenService {
  onbekendeWoordenCount = 0;

  setOnbekendeWoordenCount(count) {
    this.onbekendeWoordenCount = count;
    console.log("Count: " + this.onbekendeWoordenCount);
  }

  getAll() {
    return rootRef;
  }

  create(item) {
    this.onbekendeWoordenCount++;
    item.ID = this.onbekendeWoordenCount;
    if (item.ID > 400) {
      console.log(item);
      return firebase
        .ref(
          "/1ReIEk1F8_9yTjLz0tNjFHIg9B0ZfjClMKRQEyLS0dIU/WOORDJES/" +
            this.onbekendeWoordenCount
        )
        .set(item);
    } else {
      console.log("INTERNAL ERROR " + this.onbekendeWoordenCount);
    }
  }

  update(item) {
    console.log("Update ID " + item.ID + " " + item.Woord);
    return firebase
      .ref("/1ReIEk1F8_9yTjLz0tNjFHIg9B0ZfjClMKRQEyLS0dIU/WOORDJES/" + item.ID)
      .update(item);
  }

  delete(key) {
    return rootRef.child(key).remove();
  }

  deleteAll() {
    return rootRef.remove();
  }
}

export default new OnbekendeWoordenService();
