import Vue from "vue";
import VueRouter from "vue-router";
import Gebruikelijk from "../views/Gebruikelijk.vue";
import New from "../views/New.vue";
import Edit from "../views/Edit.vue";
import Image from "../views/Image.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/new",
    name: "New",
    component: New,
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
  },
  {
    path: "/image",
    name: "Image",
    component: Image,
  },
  {
    path: "/",
    name: "Gebruikelijk",
    component: Gebruikelijk,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
