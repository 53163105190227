import * as firebase from "firebase";
import "firebase/database";
import "firebase/storage";

let config = {
  apiKey: "AIzaSyBg6YLLH2C5po_tQ1EgiL3KBx8Tqkixh0s",
  authDomain: "onbekende-woorden.firebaseapp.com",
  databaseURL: "https://onbekende-woorden-default-rtdb.firebaseio.com",
  projectId: "onbekende-woorden",
  storageBucket: "onbekende-woorden.appspot.com",
  messagingSenderId: "841767105970",
  appId: "1:841767105970:web:65ddddaab56a2a8853a0ab",
  measurementId: "G-L2JD76K7DS",
};

var firebaseApp = firebase.initializeApp(config);
export const firebaseStorage = firebase.storage();
export default firebase.database();
